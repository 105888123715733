export const CARD_IMAGE_PLACEMENTS = {
  TOP: 'TOP',
  TOP_FULL: 'TOP_FULL',
  LEFT: 'LEFT',
  LEFT_FULL: 'LEFT_FULL',
} as const;

export const FEATURETTE_IMAGE_PLACEMENTS = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
} as const;

export const LOCALES = {
  de: 'de',
  en: 'en',
} as const;

export const DEFAULT_LOCALE = LOCALES.de;

export const IMAGE_SIZES = {
  thumbnail: 'thumbnail',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
  original: 'original',
} as const;

export const PAGE_SECTIONS = {
  HOW_IT_WORKS: 'page-components.how-it-works',
  SUBSCRIPTIONS: 'page-components.subscriptions',
  GIFT_CARD: 'page-components.gift-card',
  ARTICLES: 'page-components.articles',
  REVIEWS: 'page-components.reviews',
  QUOTES: 'page-components.quotes',
  SELECT_STARTER_BOX: 'page-components.select-starter-box',
};

export const EVENTS = {
  visited: {
    name: 'Visited store page',
    code: 'page_visit',
  },
  addedToCart: {
    name: 'Added to cart',
    code: 'added_to_cart',
  },
  addToCartError: {
    name: 'Add to cart error',
    code: 'add_to_cart_error',
  },
  removedFromCart: {
    name: 'Removed from cart',
    code: 'removed_from_cart',
  },
  checkedOut: {
    name: 'Went to checkout',
    code: 'initiate_checkout',
  },
  clickedCTA: {
    name: 'Clicked CTA',
    code: 'clicked_cta',
  },
  selectedBox: {
    name: 'Selected box',
    code: 'selected_box',
  },
} as const;

export const ctaClickSources = {
  hero: {
    name: 'Hero CTA',
  },
  starterBoxSection: {
    boxPreview: {
      name: 'Select Starter Box Section - Box preview CTA',
    },
    sectionCTA: {
      name: 'Select Starter Box section CTA',
    },
  },
  subscriptionSection: {
    name: 'Subscription section CTA',
  },
  subscriptionCard: {
    name: 'Subscription card CTA',
  },
  boxSelectorSection: {
    name: 'Box selector CTA',
  },
  giftCardSection: {
    addToCart: {
      name: 'Gift Card section - Add to cart CTA',
    },
    seeAll: {
      name: 'Gift Card section - See all CTA',
    },
  },
  newsletterForm: {
    name: 'Subscribe to newsletter CTA',
  },
  popupForm: {
    name: 'Information from popup CTA',
  },
  navbar: {
    name: 'Navigation Bar Cart CTA',
  },
  cartDropdown: {
    emptyCart: {
      name: 'Cart Dropdown CTA (empty cart)',
    },
    nonEmptyCart: {
      name: 'Cart Dropdown CTA (cart with items)',
    },
  },
  cartPage: {
    emptyCart: {
      name: 'Cart Page CTA (empty cart)',
    },
    nonEmptyCart: {
      name: 'Cart Page CTA (place order)',
    },
    continueShopping: {
      name: 'Cart Page - Continue shopping CTA',
    },
  },
  blog: {
    readMore: {
      name: 'Blog - Read more CTA',
    },
    filters: {
      name: 'Blog - Toggle filters CTA',
    },
  },
};

export const MAILCHIMP_STATUSES = {
  error: 'error',
  sending: 'sending',
  success: 'success',
};

export const LOCALIZATION_KEYS = {
  blog_read_more_btn: 'blog_read_more_btn',
  blog_filter_toggle_btn: 'blog_filter_toggle_btn',
  blog_clear_filters_btn: 'blog_clear_filters_btn',
  blog_sort_by_label: 'blog_sort_by_label',
  blog_filter_by_age_group_label: 'blog_filter_by_age_group_label',
  blog_filter_by_category_label: 'blog_filter_by_category_label',
  blog_filter_by_author_label: 'blog_filter_by_author_label',
  blog_sort_by_newest: 'blog_sort_by_newest',
  blog_sort_by_oldest: 'blog_sort_by_oldest',
  blog_last_article: 'blog_last_article',
  blog_articles_list: 'blog_articles_list',
  blog_related_articles_list: 'blog_related_articles_list',
  blog_popular_articles_list: 'blog_popular_articles_list',
  blog_no_results: 'blog_no_results',
  blog_no_results_helper: 'blog_no_results_helper',
  blog_published_on: 'blog_published_on',
  blog_all_by_author_btn: 'blog_all_by_author_btn',
  blog_written_by: 'blog_written_by',
  blog_results_for: 'blog_results_for',

  cart_btn: 'cart_btn',
  cart_item_label: 'cart_item_label',
  cart_quantity_label: 'cart_quantity_label',
  cart_price_label: 'cart_price_label',
  cart_remove_btn: 'cart_remove_btn',
  cart_price_per_month: 'cart_price_per_month',
  cart_price_per_year: 'cart_price_per_year',
  cart_pay: 'cart_pay',
  cart_pay_monthly: 'cart_pay_monthly',
  cart_pay_yearly: 'cart_pay_yearly',
  cart_subtotal_label: 'cart_subtotal_label',
  cart_subtotal_note: 'cart_subtotal_note',
  cart_subscription_annual: 'cart_subscription_annual',
  cart_subscription_biannual: 'cart_subscription_biannual',
  cart_subscription_monthly: 'cart_subscription_monthly',
  cart_toggle_monthly_label: 'cart_toggle_monthly_label',
  cart_toggle_prepayment_label: 'cart_toggle_prepayment_label',
  cart_nodge: 'cart_nodge',
  cart_save_prepaid: 'cart_save_prepaid',

  cart_dropdown_title: 'cart_dropdown_title',
  cart_dropdown_btn: 'cart_dropdown_btn',

  cart_item_change_subscription_btn: 'cart_item_change_subscription_btn',
  cart_item_change_subscription_modal_title:
    'cart_item_change_subscription_modal_title',
  cart_item_change_subscription_modal_cancel_btn:
    'cart_item_change_subscription_modal_cancel_btn',
  cart_item_change_subscription_modal_continue_btn:
    'cart_item_change_subscription_modal_continue_btn',
  cart_empty_text: 'cart_empty_text',
  cart_empty_btn: 'cart_empty_btn,',
  cart_dropdown_empty_btn: 'cart_dropdown_empty_btn',

  activity_guide_weeks: 'activity_guide_weeks',
  activity_guide_months: 'activity_guide_months',
  activity_guide_toys_list_title: 'activity_guide_toys_list_title',
  activity_guide_from: 'activity_guide_from',
  activity_guide_benefits: 'activity_guide_benefits',
  activity_guide_how_to_use: 'activity_guide_how_to_use',

  subscription_monthly: 'subscription_monthly',
  subscription_yearly: 'subscription_yearly',
  subscription_bi_yearly: 'subscription_bi_yearly',
  subscription_select: 'subscription_select',
  subscription_text: 'subscription_text',
  subscription_best_deal: 'subscription_best_deal',
  subscription_month: 'subscription_month',

  gift_cards_option_description: 'gift_cards_option_description',
  gift_cards_add_to_cart_btn: 'gift_cards_add_to_cart_btn',
  gift_cards_options_title: 'gift_cards_options_title',

  boxes_benefits: 'boxes_benefits',

  boxes_select_box: 'boxes_select_box',
} as const;

export const COOKIE_CONSENT_NAME = 'tribu_cookie_consent';
export const CART_PATH = '/cart';

export const TRIBU_MAIN_TITLE = 'Tribu';
