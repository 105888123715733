import React, {
  type ChangeEvent,
  useEffect,
  useState,
  type FormEvent,
} from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import validator from 'validator';
import { type EmailFormFields } from 'react-mailchimp-subscribe';
import cookies from 'js-cookie';
import { MAILCHIMP_STATUSES, ctaClickSources } from '../../lib/constants';
import styles from '../../styles/Popup.module.css';
import { clickedCTA } from '../../store/actions/events';
import { convergeTracking } from '../../lib/utils/converge';

export type PopUpProps = {
  readonly content: {
    title: string;
    subtitle: string;
    extraInformation?: string;
    buttonText: string;
    placeholder: string;
    invalidEmailErrorMessage: string | undefined;
    errorMessage: string | undefined;
    successTitle: string | undefined;
    successDescription: string | undefined;
  };
  readonly backgroundImage: string;
  readonly backgroundColor?: string;
  readonly decoratorImage: string;
  readonly status: any;
  readonly onValidated: (data: EmailFormFields) => boolean;

  readonly onClosed: () => void;
};

export function PopUp(props: PopUpProps) {
  const dispatch = useDispatch();
  const router = useRouter();

  const [error, setError] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [formSent, setFormSent] = useState<boolean>(false);

  const handleFormSubmit = (event: FormEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }

    if (props.status === MAILCHIMP_STATUSES.sending) {
      return;
    }

    if (!validator.isEmail(email ?? '')) {
      setError(props.content.invalidEmailErrorMessage);
      return;
    }

    dispatch(
      clickedCTA({
        name: ctaClickSources.popupForm.name,
        url: router.asPath,
        email,
      })
    );

    cookies.set('popup-subscribed', 'OK');

    setFormSent(true);

    const isFormValidated = props.onValidated({ EMAIL: email ?? '' }); // eslint-disable-line @typescript-eslint/naming-convention

    if (isFormValidated) {
      convergeTracking.trackSubscribedToNewsletter(email ?? '');
    }

    return isFormValidated;
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.cancelable) {
      event.preventDefault();
    }

    setError('');
    setEmail(event.target.value);
  };

  const handelPopupClose = () => {
    props.onClosed();
  };

  useEffect(() => {
    if (props.status === MAILCHIMP_STATUSES.error) {
      setError(props.content.errorMessage);
    }
  }, [props.status, props.content.errorMessage]);

  return (
    <div className={styles.background}>
      <div
        className={[
          styles.container,
          'col-10 col-md-8 col-lg-8 col-xl-6 col-xxl-4',
        ].join(' ')}
        style={{
          ...(props.backgroundColor
            ? { backgroundColor: props.backgroundColor }
            : { backgroundColor: 'white' }),
        }}
      >
        <Button
          variant="light"
          className="position-absolute end-0 top-0 m-3"
          onClick={handelPopupClose}
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </Button>
        <Container>
          <Row className="justify-content-center">
            <Col md={0} className="px-0">
              <div
                className={[styles.backgroundImage, 'w-100 h-100'].join(' ')}
                style={{
                  ...(props.backgroundImage
                    ? { backgroundImage: 'url(' + props.backgroundImage + ')' }
                    : {}),
                }}
              />
            </Col>
            <Col md={6} className="py-5 px-3">
              <Row className="justify-content-center mb-2">
                <Col className="text-start px-0" md={10}>
                  <h5>{props.content.title}</h5>
                </Col>
              </Row>
              <Row className="justify-content-center mb-2">
                <Col className="text-start px-0" md={10}>
                  <div className="caption mb-3">{props.content.subtitle}</div>
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center mb-4">
                {props.decoratorImage && (
                  <Col className="d-flex justify-content-end text-center">
                    <img
                      src={props.decoratorImage}
                      className="img-fluid"
                      alt=""
                    />
                  </Col>
                )}
                <Col className="text-center px-0 d-flex justify-content-center align-items-center col-8">
                  <h5 className="mb-0 display-6">
                    {props.content.extraInformation}
                  </h5>
                </Col>
                {props.decoratorImage && (
                  <Col className="d-flex justify-content-start text-center">
                    <img
                      src={props.decoratorImage}
                      className={[styles.flip, 'img-fluid'].join(' ')}
                      alt=""
                    />
                  </Col>
                )}
              </Row>
              <Form className="position-relative" onSubmit={handleFormSubmit}>
                <Row className="justify-content-center">
                  {!formSent && (
                    <Col md={10}>
                      <div className="d-flex gap-3 w-100 mb-2 flex-column">
                        <div className="py-2">
                          <p className="mb-1">{props.content.placeholder}</p>
                          <Form.Control
                            type="text"
                            value={email}
                            disabled={
                              props.status === MAILCHIMP_STATUSES.sending
                            }
                            className="bg-gray-10"
                            onChange={(event) => {
                              handleInputChange(event);
                            }}
                          />
                        </div>
                        <div className="text-center">
                          <Button
                            size="lg"
                            disabled={
                              props.status === MAILCHIMP_STATUSES.sending
                            }
                            onClick={handleFormSubmit}
                          >
                            {props.status === MAILCHIMP_STATUSES.sending ? (
                              <FontAwesomeIcon spin icon={faCircleNotch} />
                            ) : (
                              <span>{props.content.buttonText}</span>
                            )}
                          </Button>
                        </div>
                      </div>
                      {error && (
                        <div className="text-warning-red caption fw-bold align-self-start position-absolute bottom-0">
                          {error}
                        </div>
                      )}
                    </Col>
                  )}
                  {formSent && (
                    <Col md={10}>
                      <div className="d-flex gap-3 w-100 mb-2 text-center">
                        <div className="py-2">
                          <h6>{props.content.successTitle}</h6>
                          <div className="caption mb-3">
                            {props.content.successDescription}
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
