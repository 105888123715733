import qs from "qs";

const homescreen = () => {
  const query = qs.stringify({
    populate: [
      'heroBackgroundImage',
      'heroMobileBackgroundImage',
      'blocks.backgroundImage',
      'blocks.backgroundPattern',
      'blocks.image',
      'blocks.howItWorksStep.icon',
      'blocks.titleImage',
      'blocks.ageFilters'
    ]
  })
  return '/home-page?' + query
}

export default homescreen