import React, {
  type ChangeEvent,
  useEffect,
  useState,
  type FormEvent,
} from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCircleNotch,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { type EmailFormFields } from 'react-mailchimp-subscribe';
import { ctaClickSources, MAILCHIMP_STATUSES } from '../../lib/constants';
import styles from '../../styles/NewsletterForm.module.css';
import { clickedCTA } from '../../store/actions/events';
import { convergeTracking } from '../../lib/utils/converge';

export function NewsletterForm(props: {
  readonly title: string;
  readonly subtitle: string;
  readonly buttonText: string;
  readonly placeholder: string;
  readonly backgroundImage?: string;
  readonly backgroundColor?: string;
  readonly status: any;
  readonly onValidated: (data: EmailFormFields) => boolean;
  readonly invalidEmailErrorMessage: string | undefined;
  readonly errorMessage: string | undefined;
  readonly successTitle: string | undefined;
  readonly successDescription: string | undefined;
}) {
  const dispatch = useDispatch();
  const router = useRouter();

  const [error, setError] = useState<string>();
  const [email, setEmail] = useState<string>();

  const handleFormSubmit = (event: FormEvent) => {
    if (event.cancelable) event.preventDefault();
    if (props.status === MAILCHIMP_STATUSES.sending) return;
    const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    const invalidMailAddress = !email || !emailRegex.test(email);
    if (invalidMailAddress) {
      setError(props.invalidEmailErrorMessage);
      return;
    }

    const isFormValidated = props.onValidated({ EMAIL: email }); // eslint-disable-line @typescript-eslint/naming-convention
    dispatch(
      clickedCTA({
        name: ctaClickSources.newsletterForm.name,
        url: router.asPath,
        email,
      })
    );
    if (isFormValidated) {
      convergeTracking.trackSubscribedToNewsletter(email);
    }

    return isFormValidated;
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.cancelable) event.preventDefault();
    setError('');
    setEmail(event.target.value);
  };

  useEffect(() => {
    if (props.status === MAILCHIMP_STATUSES.error) setError(props.errorMessage);
  }, [props.status]);

  if (
    !props.subtitle &&
    !props.buttonText &&
    !props.placeholder &&
    !props.backgroundImage &&
    !props.backgroundColor
  )
    return null;
  return (
    <div
      id="newsletter"
      className={[styles.container, 'd-flex'].join(' ')}
      style={{
        ...(props.backgroundImage
          ? { backgroundImage: `url(${props.backgroundImage})` }
          : {}),
        ...(props.backgroundColor
          ? { backgroundColor: props.backgroundColor }
          : {}),
      }}
    >
      <>
        <Row>
          <Col>
            <div className="position-relative mb-4">
              {props.status === MAILCHIMP_STATUSES.success && (
                <div
                  className={[
                    'position-absolute top-0 start-0 end-0 d-flex flex-column align-items-center rounded',
                    styles.message,
                  ].join(' ')}
                >
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{ fontSize: '1.125rem' }}
                    className="pb-3 text-success-green"
                  />
                  <h6>{props.successTitle}</h6>
                  <div className="caption">{props.successDescription}</div>
                </div>
              )}
              <div
                className={
                  props.status === MAILCHIMP_STATUSES.success ? 'opacity-0' : ''
                }
              >
                {props.subtitle && (
                  <Row className="justify-content-center mb-2">
                    <Col>
                      <div
                        className={[
                          styles.subscription,
                          'caption-small mb-3',
                        ].join(' ')}
                        dangerouslySetInnerHTML={{ __html: props.subtitle }}
                      ></div>
                    </Col>
                  </Row>
                )}
                <Form className="position-relative" onSubmit={handleFormSubmit}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="d-flex gap-3">
                        <div className={styles['form-wrapper']}>
                          <Form.Control
                            type="text"
                            className="p-3"
                            placeholder={props.placeholder}
                            value={email}
                            disabled={
                              props.status === MAILCHIMP_STATUSES.sending
                            }
                            onChange={(event) => {
                              handleInputChange(event);
                            }}
                          />
                        </div>
                        <div className={styles['button-wrapper']}>
                          <Button
                            disabled={
                              props.status === MAILCHIMP_STATUSES.sending
                            }
                            onClick={handleFormSubmit}
                          >
                            {props.status === MAILCHIMP_STATUSES.sending ? (
                              <FontAwesomeIcon spin icon={faCircleNotch} />
                            ) : (
                              <span className="d-inline">
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                            )}
                          </Button>
                        </div>
                      </div>
                      {error && (
                        <div className="text-warning-red caption fw-bold align-self-start position-absolute bottom-0">
                          {error}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </>
    </div>
  );
}
